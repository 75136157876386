import React from 'react';
import CallToAction from '../../../molecules/CallToAction/CallToAction';
import Section from '../../../molecules/Section/Section';
import { colors } from '../../../styles/atoms/colors';
import SolutionsCard from '../../Cards/SolutionsCard';
import CardDeckHeader from '../components/CardDeckHeader';
import { Grid, Wrapper } from '../styles/SolutionsCardDeck.styled';

const SolutionsCardDeck = ({ component }) => {
  const { id, cards, background, cta } = component;

  return (
    <Section id={id} bgColor={background || colors.base.white}>
      <Wrapper>
        <CardDeckHeader {...component} />
        <Grid numOfCards={cards?.length}>
          {cards &&
            cards.map(card => <SolutionsCard key={card.id} {...card} />)}
        </Grid>
        {
          cta
            ?
            (
              <div className="cta-container">
                <CallToAction
                  variant={cta.variant}
                  size={cta.size}
                  value={cta.label}
                  link={cta.url}
                  icon={cta.icon}
                  reverse={cta.reverse}
                />
              </div>
            )
            :
            ''
        }
      </Wrapper>
    </Section>
  );
};

export default SolutionsCardDeck;
